<script>
import { ref, watch, nextTick } from 'vue';

export default {
  props: {
    digit: {
      type: Number,
      required: true,
    },
    position: {
      type: [Number, null],
      default: null,
    },
  },
  setup(props) {
    const animate = ref(false);

    const triggerAnimation = () => {
      animate.value = false; // Reset animation
      nextTick(() => {
        animate.value = true; // Trigger animation
      });
    };

    const resetAnimation = () => {
      animate.value = false; // Ensure the class is removed after animation ends
    };

    watch(
      () => props.digit,
      () => {
        triggerAnimation(); // Trigger animation on `digit` prop change
      },
    );

    return {
      animate,
      resetAnimation,
    };
  },
};
</script>

<template>
  <div class="digit-container flex-1">
    <div class="digit" :class="{ animate }" @animationend="resetAnimation">
      {{ digit }}
    </div>
  </div>
</template>

<style scoped>
.digit-container {
  height: 70px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 3rem;
  font-weight: bold;
  background: white;
  color: black;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.digit {
  position: absolute;
  top: 0;
  animation: none;
}
.digit.animate {
  animation: flip 0.6s ease-out;
}
@keyframes flip {
  0% {
    transform: rotateX(0);
  }
  50% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0);
  }
}
</style>

<template>
  <div
    class="min-h-screen flex flex-col justify-center items-center bg-gradient-to-br from-[#0C0C0C] to-[#BC8809] text-white"
  >
    <img
      src="@/assets/gold-logo-transparent.png"
      alt=""
      class="w-64 top-10 absolute"
    />
    <h1 class="text-4xl font-bold mb-6">🎉 Live User Count 🎉</h1>
    <div class="text-center">
      <div class="flex space-x-1 w-[30vw]">
        <DigitAnimation
          v-for="(digit, index) in digits"
          :key="index"
          :digit="digit"
          :position="positions[index]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DigitAnimation from '@/components/DigitAnimation.vue';

export default {
  components: { DigitAnimation },
  data() {
    return {
      userCount: 5008,
      digits: [],
      positions: [],
    };
  },
  methods: {
    startCounter() {
      setInterval(() => {
        const increment = Math.random() < 0.5 ? 0 : 1; // Random increment
        this.userCount += increment;
        this.updateDigits();
      }, 2000);
    },
    updateDigits() {
      const countString = this.userCount.toString();
      this.digits = [...countString].map(Number);
      // Update position for animation
      this.positions = this.digits.map((digit, i) => {
        const oldDigit = this.digits[i] - 1 || 0;
        return digit;
      });
    },
  },
  mounted() {
    this.updateDigits(); // Initialize digits
    this.startCounter();
  },
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: "Inter", sans-serif;
}
</style>

<script lang="ts" setup>
import * as amplitude from '@amplitude/analytics-browser';
import { computed, onMounted, ref } from 'vue';
import ServiceShowcase from '@/components/ServiceShowcase.vue';
import PartnerCompaniesSection from '@/components/PartnerCompaniesSection.vue';
import LatestNewsSection from '@/components/LatestNewsSection.vue';
import CustomerReviews from '@/components/CustomerReviews.vue';
import KeyFeaturesSection from '@/components/KeyFeaturesSection.vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';

const navigation = [
  { name: 'Services', href: '#services' },
  { name: 'How it works', href: '#howitworks' },
  { name: 'Partners', href: '#partners' },
  { name: 'Reviews', href: '#reviews' },
  { name: 'Key features', href: '#features' },
];

const mobileMenuOpen = ref(false);

function openSocials(socialNetwork: string) {
  switch (socialNetwork) {
    case 'instagram':
      window.open('https://www.instagram.com/talab_uk', '_blank');
      break;
    case 'tiktok':
      window.open('https://www.tiktok.com/@talabuk', '_blank');
      break;
    case 'linkedin':
      window.open(' https://www.linkedin.com/company/talabuk', '_blank');
      break;
    default:
      window.open('https://x.com/talab_uk', '_blank');
      break;
  }
}

function onDownloadBtnClick() {
  amplitude.track('download_btn_click');
}

const isIOS = computed(() => /iPhone|iPad|iPod/i.test(navigator.userAgent));
const isAndroid = computed(() => /Android/i.test(navigator.userAgent));

function scrollToTop() {
  window.scrollTo(0, 0);
}

onMounted(() => {
  const video: any = document.getElementById('video');
  if (video) video.play();

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
      }
      // else entry.target.classList.remove('show');
    });
  });

  const hidenElements = document.querySelectorAll('.anim');
  hidenElements.forEach((el) => observer.observe(el));
});
</script>

<template>
  <div class="bg-white">
    <NavBar />

    <!-- HERO SECTION -->
    <div class="relative isolate px-6 pt-14 lg:px-8 anim slide-up">
      <div
        class="absolute inset-x-0 -top-40 -z-20 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          "
        />
      </div>
      <div class="mx-auto max-w-4xl py-16 sm:py-24 lg:py-16">
        <div class="text-center">
          <h1
            class="font-bold tracking-tight text-gray-900 text-6xl text-center md:text-7xl"
          >
            Introducing Ultimate Student Concierge App
          </h1>
          <p class="mt-6 text-lg leading-8 text-gray-600">
            The app designed to make your student life in London effortless and
            enjoyable. Experience unparalleled convenience and premium services,
            all at your fingertips.
          </p>
          <div
            class="mt-10 flex flex-col md:flex-row gap-10 items-center justify-center gap-x-6"
          >
            <div v-if="isIOS">
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/talab-ultimate-student-app/id6503147995"
                @click="onDownloadBtnClick"
                class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
              >
                <!-- iOS SVG Icon -->
                <svg
                  class="me-3 w-7 h-7"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="apple"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  ></path>
                </svg>
                <div class="text-left rtl:text-right">
                  <div class="mb-1 text-xs">Download on the</div>
                  <div class="-mt-1 font-sans text-sm font-semibold">
                    App Store
                  </div>
                </div>
              </a>
            </div>

            <div v-else-if="isAndroid">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.talabapp.talab&hl=en_GB"
                @click="onDownloadBtnClick"
                class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
              >
                <!-- Android SVG Icon -->
                <svg
                  fill="#ffffff"
                  class="me-3 w-7 h-7"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 553.048 553.048"
                  xml:space="preserve"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <g>
                        <path
                          d="M76.774,179.141c-9.529,0-17.614,3.323-24.26,9.969c-6.646,6.646-9.97,14.621-9.97,23.929v142.914 c0,9.541,3.323,17.619,9.97,24.266c6.646,6.646,14.731,9.97,24.26,9.97c9.522,0,17.558-3.323,24.101-9.97 c6.53-6.646,9.804-14.725,9.804-24.266V213.039c0-9.309-3.323-17.283-9.97-23.929C94.062,182.464,86.082,179.141,76.774,179.141z"
                        ></path>
                        <path
                          d="M351.972,50.847L375.57,7.315c1.549-2.882,0.998-5.092-1.658-6.646c-2.883-1.34-5.098-0.661-6.646,1.989l-23.928,43.88 c-21.055-9.309-43.324-13.972-66.807-13.972c-23.488,0-45.759,4.664-66.806,13.972l-23.929-43.88 c-1.555-2.65-3.77-3.323-6.646-1.989c-2.662,1.561-3.213,3.764-1.658,6.646l23.599,43.532 c-23.929,12.203-42.987,29.198-57.167,51.022c-14.18,21.836-21.273,45.698-21.273,71.628h307.426 c0-25.924-7.094-49.787-21.273-71.628C394.623,80.045,375.675,63.05,351.972,50.847z M215.539,114.165 c-2.552,2.558-5.6,3.831-9.143,3.831c-3.55,0-6.536-1.273-8.972-3.831c-2.436-2.546-3.654-5.582-3.654-9.137 c0-3.543,1.218-6.585,3.654-9.137c2.436-2.546,5.429-3.819,8.972-3.819s6.591,1.273,9.143,3.819 c2.546,2.558,3.825,5.594,3.825,9.137C219.357,108.577,218.079,111.619,215.539,114.165z M355.625,114.165 c-2.441,2.558-5.434,3.831-8.971,3.831c-3.551,0-6.598-1.273-9.145-3.831c-2.551-2.546-3.824-5.582-3.824-9.137 c0-3.543,1.273-6.585,3.824-9.137c2.547-2.546,5.594-3.819,9.145-3.819c3.543,0,6.529,1.273,8.971,3.819 c2.438,2.558,3.654,5.594,3.654,9.137C359.279,108.577,358.062,111.619,355.625,114.165z"
                        ></path>
                        <path
                          d="M123.971,406.804c0,10.202,3.543,18.838,10.63,25.925c7.093,7.087,15.729,10.63,25.924,10.63h24.596l0.337,75.454 c0,9.528,3.323,17.619,9.969,24.266s14.627,9.97,23.929,9.97c9.523,0,17.613-3.323,24.26-9.97s9.97-14.737,9.97-24.266v-75.447 h45.864v75.447c0,9.528,3.322,17.619,9.969,24.266s14.73,9.97,24.26,9.97c9.523,0,17.613-3.323,24.26-9.97 s9.969-14.737,9.969-24.266v-75.447h24.928c9.969,0,18.494-3.544,25.594-10.631c7.086-7.087,10.631-15.723,10.631-25.924V185.45 H123.971V406.804z"
                        ></path>
                        <path
                          d="M476.275,179.141c-9.309,0-17.283,3.274-23.93,9.804c-6.646,6.542-9.969,14.578-9.969,24.094v142.914 c0,9.541,3.322,17.619,9.969,24.266s14.627,9.97,23.93,9.97c9.523,0,17.613-3.323,24.26-9.97s9.969-14.725,9.969-24.266V213.039 c0-9.517-3.322-17.552-9.969-24.094C493.888,182.415,485.798,179.141,476.275,179.141z"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                <div class="text-left rtl:text-right">
                  <div class="mb-1 text-xs">Get it on</div>
                  <div class="-mt-1 font-sans text-sm font-semibold">
                    Google Play
                  </div>
                </div>
              </a>
            </div>

            <div v-else class="flex gap-3">
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/talab-ultimate-student-app/id6503147995"
                @click="onDownloadBtnClick"
                class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
              >
                <!-- iOS SVG Icon -->
                <svg
                  class="me-3 w-7 h-7"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="apple"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  ></path>
                </svg>
                <div class="text-left rtl:text-right">
                  <div class="mb-1 text-xs">Download on the</div>
                  <div class="-mt-1 font-sans text-sm font-semibold">
                    App Store
                  </div>
                </div>
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.talabapp.talab&hl=en_GB"
                @click="onDownloadBtnClick"
                class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
              >
                <!-- Android SVG Icon -->
                <svg
                  fill="#ffffff"
                  class="w-7 h-7 me-3"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 553.048 553.048"
                  xml:space="preserve"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <g>
                        <path
                          d="M76.774,179.141c-9.529,0-17.614,3.323-24.26,9.969c-6.646,6.646-9.97,14.621-9.97,23.929v142.914 c0,9.541,3.323,17.619,9.97,24.266c6.646,6.646,14.731,9.97,24.26,9.97c9.522,0,17.558-3.323,24.101-9.97 c6.53-6.646,9.804-14.725,9.804-24.266V213.039c0-9.309-3.323-17.283-9.97-23.929C94.062,182.464,86.082,179.141,76.774,179.141z"
                        ></path>
                        <path
                          d="M351.972,50.847L375.57,7.315c1.549-2.882,0.998-5.092-1.658-6.646c-2.883-1.34-5.098-0.661-6.646,1.989l-23.928,43.88 c-21.055-9.309-43.324-13.972-66.807-13.972c-23.488,0-45.759,4.664-66.806,13.972l-23.929-43.88 c-1.555-2.65-3.77-3.323-6.646-1.989c-2.662,1.561-3.213,3.764-1.658,6.646l23.599,43.532 c-23.929,12.203-42.987,29.198-57.167,51.022c-14.18,21.836-21.273,45.698-21.273,71.628h307.426 c0-25.924-7.094-49.787-21.273-71.628C394.623,80.045,375.675,63.05,351.972,50.847z M215.539,114.165 c-2.552,2.558-5.6,3.831-9.143,3.831c-3.55,0-6.536-1.273-8.972-3.831c-2.436-2.546-3.654-5.582-3.654-9.137 c0-3.543,1.218-6.585,3.654-9.137c2.436-2.546,5.429-3.819,8.972-3.819s6.591,1.273,9.143,3.819 c2.546,2.558,3.825,5.594,3.825,9.137C219.357,108.577,218.079,111.619,215.539,114.165z M355.625,114.165 c-2.441,2.558-5.434,3.831-8.971,3.831c-3.551,0-6.598-1.273-9.145-3.831c-2.551-2.546-3.824-5.582-3.824-9.137 c0-3.543,1.273-6.585,3.824-9.137c2.547-2.546,5.594-3.819,9.145-3.819c3.543,0,6.529,1.273,8.971,3.819 c2.438,2.558,3.654,5.594,3.654,9.137C359.279,108.577,358.062,111.619,355.625,114.165z"
                        ></path>
                        <path
                          d="M123.971,406.804c0,10.202,3.543,18.838,10.63,25.925c7.093,7.087,15.729,10.63,25.924,10.63h24.596l0.337,75.454 c0,9.528,3.323,17.619,9.969,24.266s14.627,9.97,23.929,9.97c9.523,0,17.613-3.323,24.26-9.97s9.97-14.737,9.97-24.266v-75.447 h45.864v75.447c0,9.528,3.322,17.619,9.969,24.266s14.73,9.97,24.26,9.97c9.523,0,17.613-3.323,24.26-9.97 s9.969-14.737,9.969-24.266v-75.447h24.928c9.969,0,18.494-3.544,25.594-10.631c7.086-7.087,10.631-15.723,10.631-25.924V185.45 H123.971V406.804z"
                        ></path>
                        <path
                          d="M476.275,179.141c-9.309,0-17.283,3.274-23.93,9.804c-6.646,6.542-9.969,14.578-9.969,24.094v142.914 c0,9.541,3.322,17.619,9.969,24.266s14.627,9.97,23.93,9.97c9.523,0,17.613-3.323,24.26-9.97s9.969-14.725,9.969-24.266V213.039 c0-9.517-3.322-17.552-9.969-24.094C493.888,182.415,485.798,179.141,476.275,179.141z"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                <div class="text-left rtl:text-right">
                  <div class="mb-1 text-xs">Download it on</div>
                  <div class="-mt-1 font-sans text-sm font-semibold">
                    Google Play
                  </div>
                </div>
              </a>
            </div>

            <a
              v-if="isAndroid || isIOS"
              href="#services"
              class="text-sm font-semibold leading-6 text-gray-900"
              >Read more <span aria-hidden="true">→</span></a
            >
          </div>
          <!-- <div class="w-full flex items-center justify-center">
            <a
              href="https://www.trustpilot.com/review/talabapp.co.uk"
              target="_blank"
            >
              <img
                src="@/assets/trustpilot.png"
                alt="Trustpilot TALAB"
                class="w-64"
              />
            </a>
          </div> -->
            <div
              class="trustpilot-widget mt-5"
              data-locale="en-GB"
              data-template-id="56278e9abfbbba0bdcd568bc"
              data-businessunit-id="6703bb0cc53c6130a02e8022"
              data-style-height="52px"
              data-style-width="100%"
            >
              <a
                href="https://uk.trustpilot.com/review/talabapp.co.uk"
                target="_blank"
                rel="noopener"
                >Trustpilot</a
              >
          </div>
        </div>
      </div>
      <div
        class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style="
            clip-path: polygon(
              74.1% 44.1%,
              100% 61.6%,
              97.5% 26.9%,
              85.5% 0.1%,
              80.7% 2%,
              72.5% 32.5%,
              60.2% 62.4%,
              52.4% 68.1%,
              47.5% 58.3%,
              45.2% 34.5%,
              27.5% 76.7%,
              0.1% 64.9%,
              17.9% 100%,
              27.6% 76.8%,
              76.1% 97.7%,
              74.1% 44.1%
            );
          "
        />
      </div>
    </div>

    <!-- SECTION -->
    <div class="mt-10 px-6 lg:px-8 flex flex-col" id="services">
      <div
        class="border-[1px] border-[#000000] py-1 px-6 rounded-full text-nowrap whitespace-nowrap w-3/4 md:w-auto mb-5 mx-auto anim slide-up"
      >
        <p class="text-[#000000] font-medium text-center">Featured Services</p>
      </div>
      <h1
        class="font-bold leading-tight text-gray-900 text-5xl text-center anim slide-up mb-5"
      >
        One App. Infinite <br />Service Possibilities.
      </h1>

      <div class="flex flex-col md:flex-row justify-center items-center gap-10">
        <section class="flex flex-col gap-16 anim slide-right delay-1">
          <ServiceShowcase service="cleaning" />
          <ServiceShowcase service="rental" />
          <ServiceShowcase service="university" />
        </section>
        <video
          id="video"
          class="h-[85vh] anim zoom-in"
          muted
          autoplay
          loop
          playsinline
        >
          <source src="@/assets/app-showcase-mockup.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <section class="flex flex-col gap-16 anim slide-left delay-1">
          <ServiceShowcase service="booking" reverse />
          <ServiceShowcase service="chauffeur" reverse />
          <ServiceShowcase service="personal" reverse />
        </section>
      </div>
    </div>
  </div>
  <div
    class="bg-[#f9fafb] flex flex-col items-center mt-5 py-20"
    id="howitworks"
  >
    <div
      class="border-[1px] border-[#000000] py-1 px-6 rounded-full anim slide-up"
    >
      <p class="text-[#000000] font-medium">How It Works</p>
    </div>

    <h1
      class="font-bold leading-tight text-gray-900 text-5xl text-center anim slide-up my-5 anim slide-up"
    >
      Experience The Convenience.
    </h1>

    <div
      class="flex justify-center w-screen gap-5 md:gap-20 px-20 anim zoom-in"
    >
      <img
        src="@/assets/talab-explore-mockup.webp"
        alt=""
        class="w-[45%] md:w-[25%] hover:scale-95 transition-all"
      />

      <img
        src="@/assets/talab-service-mockup.webp"
        alt=""
        class="w-[45%] md:w-[25%] hover:scale-95 transition-all"
      />

      <img
        src="@/assets/talab-chat-mockup.webp"
        alt=""
        class="w-[45%] md:w-[25%] hover:scale-95 transition-all"
      />
    </div>
  </div>
  <PartnerCompaniesSection id="partners" />

  <LatestNewsSection id="latest-news-section" />

  <CustomerReviews id="reviews" />

  <KeyFeaturesSection id="features" />

  <Footer />
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

html,
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: "Inter";
}

.gradient-text {
  background: linear-gradient(85deg, #bc8809, #dbcc8b);
  color: #bc8809;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
</style>

<template>
  <div class="bg-white py-24">
    <h2
      class="text-center text-4xl font-bold leading-8 text-gray-900 anim slide-up mb-14"
    >
      Trusted by the UK's most leading companies
    </h2>
    <div
      class="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)] anim"
    >
      <ul
        class="flex items-center justify-center md:justify-start animate-infinite-scroll-mobile md:animate-infinite-scroll gap-x-14 md:gap-x-24"
      >
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/ihateironing-logo.png"
            alt="IHateIroning UK"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/amber-logo.png"
            alt="Amber Student London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/johnsco-logo.png"
            alt="Johns&Co London Estate Agents"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/tabu-logo.png"
            alt="Tabu Club London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/bagatelle-logo.png"
            alt="Bagatelle Club"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/maddox-logo.png"
            alt="Maddox London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/cuckoo-logo.png"
            alt="Cuckoo Club London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/reign-logo.png"
            alt="Reign Showclub London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/chinois-logo.png"
            alt="Chinois London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/pirana-logo.png"
            alt="Pirana London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/sumosan-logo.png"
            alt="Sumosan Twiga London"
          />
        </li>
      </ul>
      <ul
        class="flex items-center justify-center md:justify-start animate-infinite-scroll-mobile md:animate-infinite-scroll gap-x-14 md:gap-x-24"
        aria-hidden="true"
      >
        <li class="flex-none min-w-[150px] max-w-[150px] ml-36">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/ihateironing-logo.png"
            alt="IHateIroning UK"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/amber-logo.png"
            alt="Amber Student London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/johnsco-logo.png"
            alt="Johns&Co London Estate Agents"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/tabu-logo.png"
            alt="Tabu Club London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/bagatelle-logo.png"
            alt="Bagatelle Club"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/maddox-logo.png"
            alt="Maddox London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/cuckoo-logo.png"
            alt="Cuckoo Club London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/reign-logo.png"
            alt="Reign Showclub London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/chinois-logo.png"
            alt="Chinois London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/pirana-logo.png"
            alt="Pirana London"
          />
        </li>
        <li class="flex-none min-w-[150px] max-w-[150px]">
          <img
            class="h-32 w-auto object-contain"
            src="@/assets/partners/sumosan-logo.png"
            alt="Sumosan Twiga London"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
